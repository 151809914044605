import { TiposDeBusca as TiposDeBuscaAceitos } from '../protocols';

interface TiposDeBusca {
  value: number;
  label: TiposDeBuscaAceitos;
}

export const tiposDeBuscaDefault: TiposDeBusca[] = [
  {
    value: 4,
    label: 'Check-List',
  },
];
