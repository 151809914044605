import React from 'react';
import { DataGrid, GridColumns, GridRowId } from '@material-ui/data-grid';

import { TableContainer } from './styles';

import moment from 'moment';

interface Props {
  nfs: Array<any>;
  setCod_seq: any;
  onSelectedNF: any;
  selectionModel: any;
  setSelectionModel: any;
}

export const TableNF: React.FC<Props> = ({
  nfs,
  onSelectedNF,
  selectionModel,
  setSelectionModel,
}) => {
  const columns: GridColumns = [
    {
      field: 'num_nf',
      headerName: 'NF',
      width: 200,
      disableColumnMenu: true,
      align: 'left',
    },
    {
      field: 'num_serie_nf',
      headerName: 'Série',
      width: 200,
      disableColumnMenu: true,
      align: 'left',
    },
    {
      field: 'nome_pessoa',
      headerName: 'Parceiro',
      width: 280,
    },
    {
      field: 'val_total_nf',
      headerName: 'Valor',
      width: 200,
      align: 'left',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div>
            {row.val_total_nf
              .toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })
              .replace(/^(\D+)/, '')}
          </div>
        );
      },
    },
    {
      field: 'dta_entrada_saida',
      headerName: 'Entrada',
      width: 280,
      renderCell: (params) => {
        const { row } = params;
        return (
          <>
            {moment(row.dta_entrada_saida, 'YYYY-MM-DD').format('DD/MM/YYYY')}
          </>
        );
      },
    },
  ];

  return (
    <TableContainer>
      <DataGrid
        rowsPerPageOptions={[5]}
        rows={nfs}
        columns={columns}
        pageSize={5}
        onSelectionModelChange={(selected: GridRowId[]) => {
          onSelectedNF(selected);
          setSelectionModel(selected);
        }}
        selectionModel={selectionModel}
        getRowId={(row: any) => row.cod_seq_nf}
        localeText={{
          noRowsLabel: 'Nenhum registro encontrado...',
          columnMenuLabel: 'Menu',
          columnMenuFilter: 'Filtrar',
          columnMenuHideColumn: 'Esconder',
          columnMenuUnsort: 'Não ordenar',
          columnMenuSortAsc: 'Ordernar ASC',
          columnMenuSortDesc: 'Ordernar DESC',
          columnMenuShowColumns: 'Mostrar columnas',
          footerRowSelected: (count) =>
            count !== 1
              ? `${count.toLocaleString()} linhas selecionadas`
              : `${count.toLocaleString()} linha selecionada`,
        }}
      />
    </TableContainer>
  );
};
