import * as yup from 'yup';

export const schema = yup
  .object()
  .shape({
    serie: yup.string().trim().min(1).required(),
    // yup.object().shape({
    //   label: yup.string().required(),
    //   value: yup.string().required(),
    // }),
    num_nf: yup.number().positive().required(),
  })
  .required();

export const motivoSchema = yup.object().shape({
  motivo: yup.string().required(),
});
