import React, { useRef, useState, ChangeEvent, useEffect } from 'react';
import api from '~/services/api';
import { Controller } from 'react-hook-form';

import useDebounce from '~/hooks/useDebounce';
import { BuscaProdutoProps, SelectType } from './protocols';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { Lista } from './Lista';
import { InputContainer } from './styles';
import { Form, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { tiposDeBuscaDefault } from './default';

/**
 * @param {BuscaProdutoProps} BuscaProduto - sla né
 */

export const BuscaCheckList: React.FC<BuscaProdutoProps> = ({
  label = '',
  placeholder = '',
  name,
  register,
  isError,
  control,
  buscaNasLojas,
  buscaItensInativos = false,
  getProduct,
  customOptions,
  // buscarPor,
  ...rest
}: BuscaProdutoProps) => {
  /**
   * debouncedFn
   * Hook de Debounce usado para efetuar a busca após digitação do usuário
   */
  const { debouncedFn } = useDebounce();
  /**
   * inputRef
   * Ref do input utilizado para setar a label da opção selecionada no input
   */
  const inputRef: any = useRef(null);
  /**
   * selected, setSelected
   * Opção selecionada da lista
   */
  const [tiposDeBusca, setTiposDeBusca] = useState<SelectType[]>(() => {
    if (customOptions?.buscarPor) {
      return tiposDeBuscaDefault.filter((tipo) =>
        customOptions.buscarPor?.includes(tipo.label),
      );
    }
    return tiposDeBuscaDefault;
  });
  const [selectedTipoDeBusca, setSelectedTipoDeBusca] = useState<any>({
    label: undefined,
    value: undefined,
  });
  const [selected, setSelected] = useState<any>({
    label: undefined,
    value: undefined,
  });
  /**
   * isOpen
   * Renderiza ou não a lista
   */
  const [isOpen, setIsOpen] = useState<boolean>(false);
  /**
   * filter
   * Filtro passado para a api
   */
  const [filter, setFilter] = useState<string>('');
  /**
   * limit
   * Quantidade de registros buscados por vez na api
   */
  const limit = 10;
  /**
   * fetchData
   * Método que se comunica com a api
   */

  /**
   * fetchData
   * Método que se comunica com a api
   */
  function validaFetchData(): { status: boolean; message: string } {
    if (filter === '' || selectedTipoDeBusca.value === undefined) {
      return {
        status: false,
        message: '',
      };
    }
    if (typeof buscaNasLojas === 'object' && buscaNasLojas.length <= 0) {
      return {
        status: false,
        message: 'Selecione uma loja para buscar pelo produto.',
      };
    }
    if (typeof buscaNasLojas === 'number' && buscaNasLojas <= 0) {
      return {
        status: false,
        message: 'Selecione uma loja para buscar pelo produto.',
      };
    }
    return {
      status: true,
      message: 'Selecione uma loja para buscar pelo produto.',
    };
  }
  function formataData(dataFields: any) {
    return dataFields.data.map((dt: any) => ({
      value: dt.cod_lista,
      label: dt.des_lista,
    }));
  }
  const fetchData = async ({ pageParam = 1 }): Promise<any> => {
    const isValid = validaFetchData();
    if (filter === '' && !customOptions?.searchBeforeFilter) {
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
    if (isValid.status === false) {
      if (isValid.message !== '') {
        toast.warning(isValid.message);
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
    try {
      const { data: dataFields } = await api.get('/carga/busca-checklist', {
        params: {
          page: pageParam,
          limit,
          filter,
          lojas: buscaNasLojas,
        },
      });
      const options = formataData(dataFields);
      if (!options) {
        toast.error(
          'Houve um erro ao formatar os dados da busca de produtos. Favor contatar o suporte.',
        );
        return {
          count: '0',
          data: [],
          fields: [],
          pagination: {
            lastPage: 0,
            prevPage: 1,
            startPage: 1,
          },
          success: true,
        };
      }

      if (customOptions?.showSelecionarItensContendo) {
        options.unshift({
          label: `Clique aqui para selecionar todos os itens com "${filter}"`,
          value: 0,
          filter,
          selecionarItensContendo: true,
        });
      }
      return {
        ...dataFields,
        data: options,
      };
    } catch (error: any) {
      if (error.data && error.data.message) {
        toast.error(error.data.message);
      } else {
        toast.error(String(error));
      }
      return {
        count: '0',
        data: [],
        fields: [],
        pagination: {
          lastPage: 0,
          prevPage: 1,
          startPage: 1,
        },
        success: true,
      };
    }
  };

  /**
   * useInfiniteQuery
   * Hook do react-query para listagem infinita de dados
   */
  const queryClient = useQueryClient();
  const { data, hasNextPage, fetchNextPage, isFetching, refetch } =
    useInfiniteQuery(
      `input_select_${name}`,
      ({ pageParam = 1 }) => fetchData({ pageParam }),
      {
        getNextPageParam: (lastPage, allPages) => {
          const maxPages = lastPage.pagination.lastPage + 1;
          const nextPage = allPages.length + 1;
          return nextPage <= maxPages ? nextPage : undefined;
        },
        refetchOnWindowFocus: false,
      },
    );

  useEffect(() => {
    if (customOptions?.buscarPor) {
      setTiposDeBusca(
        tiposDeBuscaDefault.filter((tipo) =>
          customOptions.buscarPor?.includes(tipo.label),
        ),
      );
      if (customOptions?.buscarPor.length === 1) {
        setSelectedTipoDeBusca(
          tiposDeBuscaDefault.find((tipo) =>
            customOptions.buscarPor?.includes(tipo.label),
          ),
        );
      }
    } else {
      setTiposDeBusca(tiposDeBuscaDefault);
    }
  }, [customOptions?.buscarPor]);

  return (
    <>
      <InputContainer>
        <Form.Group>
          <Form.Label>{label}</Form.Label>
          <Controller
            name={name}
            control={control}
            defaultValue=""
            render={({ field: { value } }) => {
              if (inputRef && inputRef.current) {
                /** A condicional abaixo limpa o input */
                if (
                  value.label !== selected.label &&
                  inputRef.current.placeholder !== placeholder
                ) {
                  inputRef.current.value = '';
                  inputRef.current.placeholder = placeholder;
                }

                if (value.label) {
                  inputRef.current.placeholder = value.label;
                }
                if (!value.label) {
                  inputRef.current.placeholder = placeholder;
                }
              }
              return (
                <>
                  <div className="select-container">
                    <label>
                      <input
                        {...register(name)}
                        type="text"
                        className={
                          isError ? 'form-control is-invalid' : 'form-control'
                        }
                        placeholder={placeholder}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          event.target.value = event.target.value.toUpperCase();
                          setFilter(event.target.value.toUpperCase());
                          if (event.target.value.length > 2)
                            debouncedFn(() => refetch(), 500);
                        }}
                        autoComplete="off"
                        onClick={(event: any) => {
                          if (selected.label) {
                            event.target.placeholder = selected.label;
                          }
                          event.target.value = '';
                          setIsOpen(true);
                        }}
                        ref={inputRef}
                        {...rest}
                      />
                      <div className="drop-indicator">
                        <span role="img" aria-label="open">
                          {filter && isFetching ? (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="spinner"
                            />
                          ) : (
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              role="presentation"
                            >
                              <path
                                d="M8.292 10.293a1.009 1.009 0 000 1.419l2.939 2.965c.218.215.5.322.779.322s.556-.107.769-.322l2.93-2.955a1.01 1.01 0 000-1.419.987.987 0 00-1.406 0l-2.298 2.317-2.307-2.327a.99.99 0 00-1.406 0z"
                                fill="currentColor"
                                fillRule="evenodd"
                              />
                            </svg>
                          )}
                        </span>
                      </div>
                    </label>
                  </div>
                  {isOpen && (
                    <Lista
                      inputRef={inputRef}
                      setIsOpen={setIsOpen}
                      setFilter={setFilter}
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                      data={data}
                      selected={selected}
                      setSelected={setSelected}
                      selectedTipoDeBusca={selectedTipoDeBusca}
                      isFetching={isFetching}
                      getProduct={getProduct}
                      queryName={`input_select_${name}`}
                      queryClient={queryClient}
                      buscaNasLojas={buscaNasLojas}
                    />
                  )}
                </>
              );
            }}
          />
        </Form.Group>
      </InputContainer>
    </>
  );
};
